.header{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 3rem;
    font-size: 20px;
    padding: 1rem;
}

.header h3{
    font-size: 35px;
}
.hancyContainer {
    display: flex;
    position: relative;
}
.hancyContainer img{
    height: 15rem;
    position: absolute;
    top: 1rem;
    left: 5rem;
    /* animation: ease-out 2s ease-out; */
}

/* @keyframes ease-out {
  0% { opacity: 0; transform: scale(0.1); }
  50% { opacity: 1; transform: scale(1.3); }
  100% { opacity: 1; transform: scale(1); }
} */
.name{
    margin-top: 1rem;
    font-size: 2rem;
    color: #fff;
}


@media only screen and (max-width: 600px)   {
    .hancyContainer img{
        height: 8rem;
        position: absolute;
        top: 0.5rem;
        left: 3rem;
    }
  }

  @media only screen and (max-width: 500px)   {
    .header{
        margin-top: 0rem;
    font-size: 16px;
    padding: 0rem 1rem;
    }
    .header h3{
        font-size: 25px;
    }
    .typed{
    margin-top: 1rem;
    }
  }

