.layoutContainer{
    background-image: url('/public/assets/images/background1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
}
/* @keyframes slide-background {
    from {
      background-position: left top;
    }
    to {
      background-position: right top;
    }
  }
 .layoutContainer {
    animation: slide-background 10s linear infinite;
  } */