
.navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    position: relative;
}
.imageContainer img{
    height: 5rem;
    width:  7rem;
}
.navbarContainer{
    display: none;
    gap: 0.5rem;
   
   
}
.navbarContainer :nth-child(2){
    border: 2px solid black;
}
.navbarContainer :nth-child(2):hover{
    background-color: #000;
    color: #FFF;
    transition: 0.8s;
}
.nav{
    color: #000;
    text-decoration: none;
    padding: 0.4rem 1rem;
    border-radius: 0.8rem;
    min-width: 5rem;
    font-size: 1.2rem;
    font-weight: 400; 
}
.hamburger{
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
}
.hamburger-line{
    height: 0.2rem;
    width: 2rem;
    border-radius: 1rem;
    background-color: #000;
}

.hamburger-menu{
    list-style: none;  
    position: absolute;
    right: -100px;
    top: 0;
    height: 100vh;
    width: 50vw;
    transition: all 0.5s ease-in;
}


.menu-active{
    display: flex;
    right: 0px;
    background-color: rgba(226, 226, 226, 0.9);
    flex-direction: column;
    padding-top: 7rem;
    align-items: center;
    gap: 1.5rem;
}
.hamburger-container{
    position: absolute;
    top: 0;
    right:  -1000px;
    transition: all 0.5s ease-in;
}
.container-active{
    display: flex;
    
    height: 100vh;
    width: 100vw;
    right: unset;
    left: 0;
   
    background-color: rgba(55, 55, 55, 0.6);
    flex-direction: column;
    padding-top: 7rem;
    align-items: center;
    gap: 1rem;
}


.menu-active li {
    font-weight: 500;
    font-size: 20px;
}

.cross-icon1{
    height: 1rem;
    background-color: #000;
}


@media screen and (min-width: 768px) {
    .navbarContainer{
        display: flex;
    }
    .hamburger{
        display: none;
    }
}
